import { Injectable } from '@angular/core';

import { WebHelperService } from '../web-helper/web-helper.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(
    private webHelperService: WebHelperService
  ) { }

  parseYoutubeLink(link: string): string {
    const regExp = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const youtubeId = link.match(regExp);
    if (youtubeId && youtubeId[1]) {
      return `https://www.youtube.com/embed/${youtubeId[1]}?rel=0&amp;showinfo=0`;
    }

    return '';
  }

  parseVimeoLink(link: string): string {
    const vimeoID = link.match(/(\d+)/g);
    const vimeoHash = link.match(/([^\/]+$)/);

    if (link.includes('vimeo')) {
      return vimeoID.length > 1 ? `https://player.vimeo.com/video/${vimeoID[0]}?h=${vimeoHash[0]}` : `https://player.vimeo.com/video/${vimeoID}`;
    }

    return '';
  }

  parseVideoLinks(link: string): string {
    if (!link) {
      return '';
    }
    return this.parseYoutubeLink(link) || this.parseVimeoLink(link);
  }

  /**
   * load cameratag.js script dynamically
   * @param onLoad - function to execute when script is loaded
   */
  loadCameraTagScript(onLoad?: any): void {
    const cameraTagScript = '//www.cameratag.com/v15/js/cameratag.min.js';

    this.webHelperService.loadScript({src: cameraTagScript}, onLoad);
  }
}
